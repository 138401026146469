
  import { defineComponent } from 'vue'
  import { config } from '@/shared/config'
  import Logo from '@/app/Logo.vue'

  export default defineComponent({
    components: {
      Logo,
    },
    props: {
      returnTo: { type: String, required: true },
    },
    data() {
      return {
        server: '',
        username: '',
        password: '',
        rememberLogin: true,
        busy: false,
        error: null,
        displayForm: false,
      }
    },
    computed: {
      hasError(): boolean {
        return this.error !== null
      },
      config: () => config
    },
    async created() {
      this.server = this.$auth.server
      this.username = this.$auth.username
      const success = await this.$auth.autoLogin()
      if (success) {
        this.$store.commit('setLoginSuccess', {
          username: this.username,
          server: this.server,
        })
        this.$router.replace(this.returnTo)
      } else {
        this.displayForm = true
      }
    },
    methods: {
      login() {
        this.error = null
        this.busy = true
        this.$auth.loginWithPassword(this.server, this.username, this.password, this.rememberLogin)
          .then(() => {
            this.$store.commit('setLoginSuccess', {
              username: this.username,
              server: this.server,
            })
            this.$router.replace(this.returnTo)
          })
          .catch(err => {
            this.error = err
          })
          .finally(() => {
            this.busy = false
          })
      }
    }
  })
