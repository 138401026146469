
  import { defineComponent } from 'vue'
  import CreatePlaylistModal from '@/playlist/CreatePlaylistModal.vue'

  export default defineComponent({
    components: {
      CreatePlaylistModal
    },
    data() {
      return {
        playlistName: '',
        showAddModal: false,
      }
    },
    computed: {
      playlists(): any[] | null {
        return this.$store.state.playlists?.slice(0, 10)
      },
    },
    methods: {
      onDrop(playlistId: string, event: any) {
        event.preventDefault()
        const trackId = event.dataTransfer.getData('id')
        return this.$store.dispatch('addTrackToPlaylist', { playlistId, trackId })
      },
      onDragover(event: any) {
        event.preventDefault()
      },
    }
  })
