
  import { defineComponent } from 'vue'

  export default defineComponent({
    computed: {
      error(): any {
        return this.$store.state.error
      }
    },
    methods: {
      clearError() {
        return this.$store.commit('clearError')
      }
    },
  })
