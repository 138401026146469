
  import { defineComponent } from 'vue'
  import CellDuration from '@/library/track/CellDuration.vue'
  import CellArtist from '@/library/track/CellArtist.vue'
  import CellAlbum from '@/library/track/CellAlbum.vue'
  import CellTrackNumber from '@/library/track/CellTrackNumber.vue'
  import CellActions from '@/library/track/CellActions.vue'
  import CellDiscNumber from './CellDiscNumber.vue'
  import CellTitle from '@/library/track/CellTitle.vue'
  import BaseTable from '@/library/track/BaseTable.vue'
  import BaseTableHead from '@/library/track/BaseTableHead.vue'
  import { Track } from '@/shared/api'

  export default defineComponent({
    components: {
      BaseTableHead,
      BaseTable,
      CellTitle,
      CellActions,
      CellTrackNumber,
      CellAlbum,
      CellArtist,
      CellDuration,
      CellDiscNumber
    },
    props: {
      tracks: { type: Array, required: true },
      noAlbum: { type: Boolean, default: false },
      noArtist: { type: Boolean, default: false },
      noDuration: { type: Boolean, default: false },
      displayDisc: { type: Boolean, default: false }
    },
    data() {
      let finalTracks: any[] = []
      const tracksByDisc: {[discNumber: string]: Track[]} = {}
      for (const track of (this.tracks as Track[])) {
        const currentDisc = track.discNumber || 'default'
        tracksByDisc[currentDisc] = [...(tracksByDisc[currentDisc] ? tracksByDisc[currentDisc] : []), track]
      }
      if (Object.keys(tracksByDisc).length === 1 && tracksByDisc.default) {
        finalTracks = [...tracksByDisc.default]
      } else {
        for (const disc in tracksByDisc) {
          finalTracks = [...finalTracks, { discNumber: disc }, ...tracksByDisc[disc]]
        }
      }
      console.log(finalTracks)
      return {
        tracksByDisc: this.displayDisc ? finalTracks : this.tracks,
      }
    },
    computed: {
      isPlaying(): boolean {
        return this.$store.getters['player/isPlaying']
      },
      playingTrackId(): any {
        return this.$store.getters['player/trackId']
      },
    },
    methods: {
      play(index: number) {
        if ((this.tracks as any)[index].id === this.playingTrackId) {
          return this.$store.dispatch('player/playPause')
        }
        return this.$store.dispatch('player/playTrackList', {
          index,
          tracks: this.tracks,
        })
      },
      dragstart(id: string, event: any) {
        event.dataTransfer.setData('id', id)
      },
    }
  })
