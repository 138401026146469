
  import { defineComponent } from 'vue'
  import ProgressBar from '@/player/ProgressBar.vue'

  export default defineComponent({
    components: {
      ProgressBar,
    },
    data() {
      return {
        oldVolume: this.$store.state.player.volume
      }
    },
    computed: {
      visible() {
        return this.$store.state.player.queue.length > 0
      },
      isPlaying() {
        return this.$store.state.player.isPlaying
      },
      volume() {
        return this.$store.state.player.volume
      },
      isMuted() {
        return this.$store.state.player.volume <= 0.0
      },
      repeatActive(): boolean {
        return this.$store.state.player.repeat
      },
      repeatCurrentTrackActive(): boolean {
        return this.$store.state.player.repeatCurrentTrack
      },
      shuffleActive(): boolean {
        return this.$store.state.player.shuffle
      },
      isFavourite(): boolean {
        return this.track && !!this.$store.state.favourites.tracks[this.track.id]
      },
      track() {
        return this.$store.getters['player/track']
      },
      streamTitle() {
        return this.$store.state.streamTitle
      },
      documentTitle(): string {
        return [
          this.streamTitle || this.track?.title,
          this.track?.artist || this.track?.album,
          'Airsonic (refix)'
        ].filter(x => !!x).join(' • ')
      },
      hotkey() {
        return {
          space: (this as any).playPause || null,
          m: (this as any).toggleMute,
          r: (this as any).toggleRepeat,
          s: (this as any).toggleShuffle,
          'ctrl+right': (this as any).next,
          'ctrl+left': (this as any).previous,
          'ctrl+up': (this as any).increaseVolume,
          'ctrl+down': (this as any).decreaseVolume,
        }
      }
    },
    watch: {
      documentTitle: {
        immediate: true,
        handler(value: string) {
          document.title = value
        }
      }
    },
    methods: {
      playPause() {
        return this.$store.dispatch('player/playPause')
      },
      next() {
        return this.$store.dispatch('player/next')
      },
      previous() {
        return this.$store.dispatch('player/previous')
      },
      setVolume(volume: any) {
        this.oldVolume = this.$store.state.player.volume
        const floatVolume = parseFloat(volume)
        const v = (floatVolume <= 0) ? 0 : (floatVolume >= 1) ? 1 : floatVolume
        return this.$store.dispatch('player/setVolume', v)
      },
      increaseVolume() {
        const v = this.$store.state.player.volume
        this.setVolume(v + 0.05)
      },
      decreaseVolume() {
        const v = this.$store.state.player.volume
        this.setVolume(v - 0.05)
      },
      toggleMute() {
        if (this.isMuted) {
          this.setVolume(this.oldVolume)
        } else {
          this.setVolume(0)
        }
      },
      toggleRepeat() {
        return this.$store.dispatch('player/toggleRepeat')
      },
      toggleRepeatCurrentTrack() {
        return this.$store.dispatch('player/toggleRepeatCurrentTrack')
      },
      toggleShuffle() {
        return this.$store.dispatch('player/toggleShuffle')
      },
      toggleFavourite() {
        return this.$store.dispatch('favourites/toggle', { id: this.track.id, type: 'track' })
      },
    }
  })
