
  import { defineComponent } from 'vue'
  import { config } from '@/shared/config'
  import Logo from './Logo.vue'
  import PlaylistNav from '@/playlist/PlaylistNav.vue'
  console.log({ config })

  export default defineComponent({
    components: {
      Logo,
      PlaylistNav,
    },
    computed: {
      config: () => config,
    },
    methods: {
      hideMenu() {
        return this.$store.dispatch('hideMenu')
      }
    }
  })
