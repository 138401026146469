
  import { defineComponent } from 'vue'

  export default defineComponent({
    data() {
      return {
        query: ''
      }
    },
    computed: {
      hotkey() {
        return {
          '/': (this as any).focusSearch,
          esc: (this as any).unfocusSearch
        }
      }
    },
    methods: {
      search(): void {
        this.$router.push({ name: 'search', query: { q: this.query } })
      },
      focusSearch(): void {
        (this as any).$refs.search.focus()
      },
      unfocusSearch(): void {
        (this as any).$refs.search.blur()
      }
    }
  })
