
  import { defineComponent } from 'vue'
  import TrackList from '@/library/track/TrackList.vue'
  import EditModal from '@/shared/components/EditModal.vue'

  export default defineComponent({
    components: {
      TrackList,
      EditModal,
    },
    props: {
      id: { type: String, required: true }
    },
    data() {
      return {
        playlist: null as any,
        showEditModal: false,
      }
    },
    watch: {
      id: {
        immediate: true,
        handler(value: string) {
          this.playlist = null
          this.$api.getPlaylist(value).then(playlist => {
            this.playlist = playlist// .sort((a: any, b:any) => a.created.localeCompare(b.created));
          })
        }
      }
    },
    methods: {
      remove(index: number) {
        this.playlist.tracks.splice(index, 1)
        return this.$api.removeFromPlaylist(this.id, index.toString())
      },
      updatePlaylist(value: any) {
        this.playlist = value
        return this.$store.dispatch('updatePlaylist', this.playlist)
      },
      deletePlaylist() {
        return this.$store.dispatch('deletePlaylist', this.id).then(() => {
          this.$router.replace({ name: 'playlists' })
        })
      },
    }
  })
