
  import { defineComponent } from 'vue'
  import SidebarNav from './SidebarNav.vue'

  export default defineComponent({
    components: {
      SidebarNav,
    },
    computed: {
      visible() {
        return this.$store.state.menuVisible
      }
    },
    methods: {
      hideMenu() {
        return this.$store.dispatch('hideMenu')
      }
    },
  })
