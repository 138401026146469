
  import { defineComponent } from 'vue'
  import { config } from '@/shared/config'

  export default defineComponent({
    data() {
      return {
        instanceName: config.instanceName,
        instanceSubName: config.instanceSubName,
        disableSubName: config.disableSubName
      }
    },
  })
