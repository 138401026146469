
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      visible: { type: Boolean, required: true },
    },
    data() {
      return {
        name: '',
      }
    },
    methods: {
      confirm() {
        return this.$store.dispatch('createPlaylist', this.name)
      },
      change() {
        this.name = ''
        this.$emit('update:visible', false)
      },
    }
  })
