
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      track: { type: Object, required: true },
    },
    data() {
      return {
        showPlaylistSelect: false,
      }
    },
    computed: {
      isFavourite(): boolean {
        return !!this.$store.state.favourites.tracks[this.track.id]
      },
      playlists(): any[] {
        return this.$store.state.playlists
      },
    },
    methods: {
      toggleFavourite() {
        return this.$store.dispatch('favourites/toggle', { id: this.track.id, type: 'track' })
      },
      download() {
        window.location.href = this.$api.getDownloadUrl(this.track.id)
      },
      setNextInQueue() {
        return this.$store.dispatch('player/setNextInQueue', [this.track])
      },
      addToQueue() {
        return this.$store.dispatch('player/addToQueue', [this.track])
      },
      addToPlaylist(playlistId: string) {
        this.showPlaylistSelect = false
        return this.$store.dispatch('addTrackToPlaylist', {
          playlistId,
          trackId: this.track.id
        })
      },
    }
  })
