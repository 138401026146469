
  import { defineComponent } from 'vue'
  import CreatePlaylistModal from '@/playlist/CreatePlaylistModal.vue'
  import { orderBy } from 'lodash-es'

  export default defineComponent({
    components: {
      CreatePlaylistModal,
    },
    props: {
      sort: { type: String, default: null },
    },
    data() {
      return {
        showAddModal: false,
      }
    },
    computed: {
      items(): any[] {
        const playlists = this.$store.state.playlists
        return this.sort === 'a-z'
          ? orderBy(playlists, 'name')
          : orderBy(playlists, 'createdAt', 'desc')
      },
      loading(): boolean {
        return this.$store.state.playlists === null
      }
    },
  })
