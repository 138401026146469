
  import { defineComponent } from 'vue'
  import About from './About.vue'
  import SearchForm from '@/search/SearchForm.vue'

  export default defineComponent({
    components: {
      About,
      SearchForm,
    },
    data() {
      return {
        showAboutModal: false
      }
    },
    computed: {
      server() {
        return this.$store.state.server
      },
      username() {
        return this.$store.state.username
      }
    },
    methods: {
      showMenu() {
        return this.$store.dispatch('showMenu')
      },
      scan() {
        return this.$api.scan()
      },
      logout() {
        this.$auth.logout()
        this.$router.go(0)
      }
    }
  })
